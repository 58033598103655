import { StyledSecondaryButton } from 'fitify-ui-onboarding/src/components/Button/Button.Styled'
import ErrorPage from 'fitify-ui-onboarding/src/components/Error/ErrorPage'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'

import { Layout } from 'components/Layout'
import { getStaticPropsTranslations } from 'utils/i18n'

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await getStaticPropsTranslations(locale)),
    },
  }
}

const Error404Page: NextPage = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const onButtonClick = () => {
    router.replace('/')
  }

  return (
    <Layout>
      <NextSeo title={t('onboarding_error_message_404') + ' | FITIFY'} />

      <ErrorPage
        error={t('onboarding_error_message_404')}
        title={t('onboarding_error_message_title')}
        subtitle={t('onboarding_error_message_subtitle')}
        cta={
          <StyledSecondaryButton onClick={onButtonClick}>
            {t('onboarding_error_message_cta')}
          </StyledSecondaryButton>
        }
      />
    </Layout>
  )
}

export default Error404Page
